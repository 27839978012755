import { FuseNavigationItem } from '@fuse/components/navigation';

export const fullNav: FuseNavigationItem[] = [
    {
        id: 'itemSearchList',
        title: 'Item Search List',
        subtitle: 'Search auctions',
        type: 'basic',
        icon: 'heroicons_solid:list-bullet',
        link: '/secure-area/item-search-list',
        meta: {
            permissions: [
                "user:all"
            ]
        }
    },
    {
        id: 'itemSearchDetail',
        title: 'Add/Update Item Search',
        subtitle: 'Item search details',
        type: 'basic',
        icon: 'heroicons_solid:book-open',
        link: '/secure-area/item-search-list/item-search-edit/0',
        meta: {
            permissions: [
                "user:all"
            ]
        }
    },
    {
        id: 'recentAlerts',
        title: 'Recent Alerts',
        subtitle: 'Alert list',
        type: 'basic',
        icon: 'heroicons_solid:bell-alert',
        link: '/secure-area/recent-alert-list',
        meta: {
            permissions: [
                "user:all"
            ]
        }
    },
    {
        id: 'appLogs',
        title: 'Application Logs',
        subtitle: 'Monitor logs',
        type: 'basic',
        icon: 'heroicons_solid:document-check',
        link: '/secure-area/admin-area/app-activity-log',
        meta: {
            permissions: [
                "admin:all"
            ]
        }
    },
    {
        id: 'scheduledTasks',
        title: 'Scheduled Tasks',
        subtitle: 'Scheduled Task List',
        type: 'basic',
        icon: 'heroicons_solid:clock',
        link: '/secure-area/admin-area/scheduled-task-list',
        meta: {
            permissions: [
                "admin:all"
            ]
        }
    },
    {
        id: 'manageUsers',
        title: 'Manage Users',
        subtitle: 'Add/Update users',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/secure-area/admin-area/user-list',
        meta: {
            permissions: [
                "admin:all"
            ]
        }
    }
]