import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { ElementReferenceService } from '@services/infrastructure/element-reference.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { checkIcon, SVGIcon } from '@progress/kendo-svg-icons';

@Component({
    selector: 'gird-column-template',
    standalone: true,
    imports: [
        NgForOf,
        NgIf,
        GridModule,
        SVGIconModule
    ],
    template: `
        <ng-template #keyPairTemplate kendoGridCellTemplate let-dataItem
                     let-column="column">
            {{ getKeyPairDisplayText(dataItem, column.field) }}
        </ng-template>
        <ng-template #boolTemplate kendoGridCellTemplate let-dataItem
                     let-column="column">
            <kendo-svg-icon *ngIf="getNestedItem(dataItem, column.field)"
                            [icon]="trueIcon">
            </kendo-svg-icon>
        </ng-template>
    `
})
export class GridColumnTemplateComponent implements AfterViewInit {

    trueIcon: SVGIcon = checkIcon;
    @ViewChild('keyPairTemplate') keyPairTemplate!: TemplateRef<any>;
    @ViewChild('boolTemplate') boolTemplate!: TemplateRef<any>;

    constructor(private elementReferenceService: ElementReferenceService) {
    }

    getKeyPairDisplayText(dataItem: any, field: string) {
        let dataElement = this.getNestedItem(dataItem, field);
        if (Array.isArray(dataElement)) {
            let pairList = dataElement as [];
            let pairDisplayList = pairList.map(x => getKeyPair(x));
            return pairDisplayList.join(', ');
        } else {
            return getKeyPair(dataElement);
        }

        function getKeyPair(dataElement: any): string {
            if (dataElement && dataElement.displayText)
                return dataElement.displayText;

            return '';
        }
    }

    ngAfterViewInit() {
        this.elementReferenceService.setItemTemplate('keyPairTemplate'
            , this.keyPairTemplate);

        this.elementReferenceService.setItemTemplate('gridColTempBool'
            , this.boolTemplate);
    }

    getNestedItem(dataItem: any, field: string) {
        let dataElement = dataItem;

        let fieldArr = field.split('.');
        fieldArr.forEach(value => {
            dataElement = dataElement[value];
        });

        return dataElement;
    }
}
