import { HttpHandlerFn, HttpRequest } from '@angular/common/http';

export function moveAuthInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
    let token = localStorage.getItem('auth_token');
    if (!token)
        return next(req);

    const authReq = req.clone({
        setHeaders: {
            'X-Authorization': `Bearer ${token}`
        }
    });
    return next(authReq);
}
