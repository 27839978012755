import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading, } from '@angular/router';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { getAppInitializer, getHttpAndInterceptorProviders, getIconProviders } from '@helpers/providers/misc.provider';
import { provideToastr } from "ngx-toastr";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { getAuth0Provider } from "@helpers/providers/auth0.provider";

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideToastr({
            autoDismiss: true,
            closeButton: true,
            progressBar: true,
            timeOut: 3000
        }),
        importProvidersFrom(NotificationModule),
        importProvidersFrom(DialogsModule),
        getHttpAndInterceptorProviders(),
        getAuth0Provider(),
        getAppInitializer(),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),
        getIconProviders(),

        // Fuse
        provideFuse({
            fuse: {
                layout: 'enterprise',
                scheme: 'dark',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px'
                },
                theme: 'theme-brand',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        })
    ],
};
