import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Shortcut } from "@models/shared/navigation.models";

@Injectable({ providedIn: 'root' })
export class ShortcutsService {

    get shortcuts$(): Observable<Shortcut[]> {
        let shortcuts = this.getHardCodedShortCuts();
        return of(shortcuts);
    }

    private getHardCodedShortCuts() {
        return [] as Shortcut[];
    }
}
