import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { environment } from '@env/environment';

export function urlMagicHttpInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn) {
    let newUrl = substituteUrl(request.url);
    request = request.clone({
        url: newUrl
    });
    return next(request);

    function substituteUrl(url: string) {
        if (!url)
            return url;

        if (!url.startsWith('@'))
            return url;

        let alias = url;
        let urlIndex = url.indexOf('/');
        if (urlIndex !== -1)
            alias = url.substring(0, urlIndex);

        let record =
            environment.urlStore.find(value => value.alias === alias);
        if (!record)
            return url;

        if (!record.overrideUrl)
            return url;

        let aliasReplacement = record.overrideUrl;
        if (record.relativeUrl)
            aliasReplacement = aliasReplacement.combineUrl(record.relativeUrl);

        return url.replace(alias, aliasReplacement);
    }
}