<!-- Button -->
<ng-container *ngIf="user != null">
    <button [matMenuTriggerFor]="userActions" mat-icon-button>
    <span class="relative">
        @if (showAvatar && user.avatar) {
            <img class="h-7 w-7 rounded-full" [src]="user.avatar"/>
        }
        @if (!showAvatar || !user.avatar) {
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        }
    </span>
    </button>

    <mat-menu #userActions="matMenu" [xPosition]="'before'">
        <div mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>Signed in as</span>
                <span class="mt-1.5 text-md font-medium text-center">
                    {{ user.email }}
                </span>
            </span>
        </div>
        <button [routerLink]="'/secure-area/user-profile'"
                mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
            <span>My Account</span>
        </button>
        <button [routerLink]="'/secure-area/contact-us'"
                mat-menu-item>
            <mat-icon [svgIcon]="'heroicons_outline:phone'"></mat-icon>
            <span>Contact Us</span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button (click)="signOut()" mat-menu-item>
            <mat-icon
                    [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
            ></mat-icon>
            <span>Sign out</span>
        </button>
    </mat-menu>
</ng-container>