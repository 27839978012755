import { EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { environment } from "@env/environment";

export const getAuth0Provider = (): Array<Provider | EnvironmentProviders> => {
    return [
        importProvidersFrom(
            AuthModule.forRoot({
                domain: environment.auth0.domain,
                clientId: environment.auth0.clientId,
                authorizationParams: {
                    redirect_uri: window.location.origin,
                    audience: environment.auth0.audience
                },
                httpInterceptor: {
                    allowedList: environment.auth0.allowedList
                }
            })),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        }
    ];
};
