<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
        [appearance]="'thin'"
        [mode]="isScreenSmall ? 'over' : 'side'"
        [name]="'mainNavigation'"
        [navigation]="navigation.compact"
        [opened]="!isScreenSmall"
        class="bg-card dark:bg-gray-900 print:hidden"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <a class="flex h-20 items-center justify-center" routerLink="/">
            <img alt="Logo image" class="w-8" src="assets/images/logo/logo.png"/>
        </a>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
            class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
    >
        <!-- Navigation toggle button -->
        <button
                (click)="toggleNavigation('mainNavigation')"
                class="mr-2"
                mat-icon-button
        >
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <shortcuts></shortcuts>
            <settings></settings>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <div app-footer
         class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-between border-t px-4 dark:bg-transparent md:px-6 print:hidden"
    >
    </div>
</div>
