import { Injectable, Type } from '@angular/core';
import { Route } from '@angular/router';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { LayoutComponent } from '@layout/layout.component';
import { RouteDictionary } from '@models/shared/shared-models';
import { MiscExtension } from "@helpers/extensions/misc-extension";

@Injectable({
    providedIn: 'root'
})
export class RouteDictionaryService {

    generateRouteDictionary(routes: Route[], parentPath: string = '', parentTitle = ''): Observable<RouteDictionary> {
        const routeObservables: Observable<RouteDictionary>[] = routes.map(route => {
            // Combine parent path with the current route's path
            const currentPath = route.path ? `${parentPath}/${route.path}` : parentPath;
            if (route.component && route.component != LayoutComponent) {
                let routeData = route.data;
                const component =
                    (routeData?.compToActivate ?? route.component) as Type<any>;
                let compSelector = MiscExtension.getSelector(component);
                let title = route.title ?? parentTitle;
                const componentDictionary: RouteDictionary = {
                    [compSelector]: [ {
                        path: currentPath,
                        title: title as string,
                        componentRef: component
                    } ]
                };
                return of(componentDictionary);
            }

            if (route.children) {
                return this.generateRouteDictionary(route.children, currentPath, route.title as string);
            } else if (route.loadChildren) {
                return this.loadChildRoutes(route).pipe(
                    switchMap(childRoutes => this.generateRouteDictionary(childRoutes, currentPath, route.title as string))
                );
            }

            return of({});
        });

        return forkJoin(routeObservables).pipe(
            map(routeDictionaries => {
                const mergedDictionary: RouteDictionary = {};
                routeDictionaries.forEach(dictionary => this.mergeDictionaries(mergedDictionary, dictionary as RouteDictionary));
                return mergedDictionary;
            })
        );
    }

    private loadChildRoutes(route: Route): Observable<Route[]> {
        const loadChildrenFn = route.loadChildren as () => Promise<any>;
        return from(loadChildrenFn()).pipe(
            map(loadedModule => {
                if (Array.isArray(loadedModule)) {
                    return loadedModule; // Case 1: Routes array directly returned.
                } else if (loadedModule?.ɵmod || loadedModule?.ngModule) {
                    // Case 2: NgModule case
                    return loadedModule;
                } else if (loadedModule?.default) {
                    // Case 3: Standalone component/module default export.
                    return loadedModule.default;
                } else if (typeof loadedModule === 'function') {
                    // Case 4: Function returning routes.
                    return loadedModule();
                } else if (loadedModule?.routes) {
                    // Case 5: Directly has routes property.
                    return loadedModule.routes;
                } else {
                    throw new Error('Unknown module format in lazy-loaded route.');
                }
            })
        );
    }

    private mergeDictionaries(target: RouteDictionary, source: RouteDictionary): void {
        Object.keys(source).forEach(key => {
            if (!target[key]) {
                target[key] = [];
            }
            target[key] = [ ...target[key], ...source[key] ];
        });
    }
}