<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
@if (isScreenSmall) {
    <fuse-vertical-navigation
            class="dark bg-gray-900 print:hidden"
            [mode]="'over'"
            [name]="'mainNavigation'"
            [navigation]="navigation.default"
            [opened]="false"
    >
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <a routerLink="/" class="flex h-20 items-center px-8 pt-6">
                <a routerLink="/" class="flex items-center text-3xl">
                    ItemPatrol
                </a>
            </a>
        </ng-container>
    </fuse-vertical-navigation>
}

<!-- Wrapper -->
<div
        class="flex w-full min-w-0 flex-auto flex-col items-center bg-gray-200 dark:bg-card"
>
    <!-- Header -->
    <div
            class="relative z-49 flex h-16 w-full flex-0 flex-col justify-center overflow-hidden shadow dark:shadow-none sm:h-20 md:h-36 print:hidden"
    >
        <!-- Top bar -->
        <div
                class="dark relative flex w-full flex-auto justify-center bg-gray-800 px-4 dark:bg-gray-900 md:px-8"
        >
            <div class="flex h-16 w-full max-w-360 items-center sm:h-20">
                <!-- Logo -->
                @if (!isScreenSmall) {
                    <a routerLink="/" class="flex items-center text-3xl">
                        <!-- Light version -->
                        <img
                                class="w-30 dark:hidden"
                                src="assets/images/logo/logo-text.png"
                                alt="Logo image"
                        />
                        <!-- Dark version -->
                        <img
                                class="hidden w-30 dark:flex"
                                src="assets/images/logo/logo-text-on-dark.png"
                                alt="Logo image"
                        />
                    </a>
                }
                <!-- Navigation toggle button -->
                @if (isScreenSmall) {
                    <button
                            mat-icon-button
                            (click)="toggleNavigation('mainNavigation')"
                    >
                        <mat-icon
                                [svgIcon]="'heroicons_outline:bars-3'"
                        ></mat-icon>
                    </button>
                }
                <!-- Components -->
                <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
                    <shortcuts></shortcuts>
                    <settings></settings>
                    <user></user>
                </div>
            </div>
        </div>
        <!-- Bottom bar -->
        @if (!isScreenSmall) {
            <div
                    class="bg-card flex flex-auto justify-center px-4 dark:bg-gray-700 md:px-8"
            >
                <div class="relative flex h-16 w-full max-w-360 items-center">
                    <fuse-horizontal-navigation
                            class="-mx-4"
                            [name]="'mainNavigation'"
                            [navigation]="navigation.horizontal"
                    ></fuse-horizontal-navigation>
                </div>
            </div>
        }
    </div>

    <!-- Content -->
    <div class="flex w-full flex-auto justify-center sm:p-6 md:p-8">
        <div
                class="bg-default flex w-full flex-auto flex-col sm:max-w-360 sm:overflow-hidden sm:rounded-lg sm:shadow-lg"
        >
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            @if (true) {
                <router-outlet></router-outlet>
            }
        </div>
    </div>

    <!-- Footer -->
    <div
            class="bg-card relative z-49 flex w-full border-t px-6 md:px-8 print:hidden"
    >
        <div app-footer class="flex h-14 w-full flex-0 justify-between items-center sm:h-20">

        </div>
    </div>
</div>