import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridColumnTemplateComponent } from '@shared/components/grid-column-template.component';
import { ComboItemTemplateComponent } from '@shared/components/combo-item-template.component';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { CustomDialogService } from '@services/infrastructure/custom-dialog.service';
import { Subject, takeUntil } from "rxjs";
import { AuthService } from "@auth0/auth0-angular";
import { UserAccountService } from "@services/pages/user-account.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ],
    standalone: true,
    imports: [
        RouterOutlet,
        DialogsModule,
        GridColumnTemplateComponent,
        ComboItemTemplateComponent
    ]
})
export class AppComponent implements OnInit, OnDestroy {

    formData = {
        destroy$: new Subject(),
        idleTimer: null as any
    }

    constructor(private router: Router,
                private dialogService: CustomDialogService,
                private authService: AuthService,
                private userService: UserAccountService) {
    }

    ngOnInit(): void {
        this.router
            .events
            .pipe(takeUntil(this.formData.destroy$))
            .subscribe((event: any) => {
                if (event instanceof NavigationStart) {
                    this.dialogService.closeAll();
                }
            });

        this.authService
            .isAuthenticated$
            .pipe(takeUntil(this.formData.destroy$))
            .subscribe((isAuthenticated) => {
                if (isAuthenticated) {
                    this.authService.getAccessTokenSilently()
                        .subscribe((token) => {
                            localStorage.setItem('auth_token', token); // Store the token in localStorage
                            this.userService.post().subscribe();
                        });
                } else {
                    localStorage.removeItem('auth_token'); // Clear token on logout
                }
            });
    }

    ngOnDestroy() {
        this.formData.destroy$.next(null);
        this.formData.destroy$.complete();
    }
}