import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AppInfoModel } from '@models/shared/shared-models';
import { lastValueFrom, mergeMap, of } from 'rxjs';
import { appRoutes } from '../../../app.routes';
import { RouteDictionaryService } from '@services/infrastructure/route-dictionary.service';

export function initializeApp(appInitService: AppInitService): () => Promise<any> {
    return () => appInitService.initializeApp();
}

@Injectable({
    providedIn: 'root'
})

export class AppInitService {

    constructor(private httpClient: HttpClient,
                private routeService: RouteDictionaryService) {

    }

    initializeApp(): Promise<any> {
        const salt = (new Date()).getTime();
        let url = `/assets/appInfo.json?${salt}`;

        return lastValueFrom(
            this.httpClient
                .get<AppInfoModel>(url)
                .pipe(mergeMap(x => {
                    environment.appInfo = x;
                    return this.routeService.generateRouteDictionary(appRoutes);
                }))
                .pipe(mergeMap(x => {
                    environment.routeDictionary = x;
                    return of(true);
                }))
        );
    }
}