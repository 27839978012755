export {}; // this file needs to be a module
String.prototype.isNullOrEmpty = function (this: string) {
    return !this;
};

String.prototype.combineUrl = function (this: string, str: string) {
    let str1 = this ?? '';
    let str2 = str ?? '';

    if (str1.endsWith('/'))
        str1 = str1.substring(0, str1.length - 1);

    if (str2.startsWith('/'))
        str2 = str2.substring(1);

    let returnStr = `${str1}/${str2}`;
    if (returnStr.endsWith('/'))
        returnStr = returnStr.substring(0, returnStr.length - 1);

    return returnStr;
};

String.prototype.contains = function (this: string, str: string) {
    let str1 = this ?? '';
    let str2 = str ?? '';

    return str1.indexOf(str2) > -1;
};

String.prototype.smartContains = function (this: string, str: string) {
    let str1 = this ?? '';
    let str2 = str ?? '';

    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();

    return str1.indexOf(str2) > -1;
};

String.prototype.formRouteUrl = function (this: string, payload: any) {
    let url = this ?? '';
    if (!payload)
        return url;

    let keys = Object.keys(payload);
    keys.forEach(value => {
        let myVal = `:${value}`;
        url = url.replace(myVal, payload[value]);
    });

    return url;
};