import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppNav } from "@models/shared/navigation.models";
import { fullNav } from "@helpers/constants/menu.constants";
import { environment } from "@env/environment";

@Injectable({ providedIn: 'root' })
export class NavigationService {

    get navigation$(): Observable<AppNav> {
        return this.get();
    }

    get(): Observable<AppNav> {
        let mainList = fullNav;
        mainList = mainList.map(x => {
            x.tooltip = x.title;
            return x;
        })

        mainList = mainList.filter(x => this.isMatch(x.meta.permissions) == true);

        let returnModel = {
            horizontal: mainList,
            compact: mainList,
            default: mainList,
            futuristic: mainList
        } as AppNav;

        return of(returnModel);
    }

    private isMatch(permissions: string[]) {
        if (!permissions)
            return true;

        let userPerms = environment.userInfo.permissions ?? [];
        let isMatch = true;

        permissions.forEach(permission => {
            if (!userPerms.includes(permission)) {
                isMatch = false;
                return;
            }
        });

        return isMatch;
    }
}
