import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { Observable } from "rxjs";
import { ProblemDetails } from "@models/shared/shared-models";
import { tap } from "rxjs/operators";
import { ToastService } from "@services/infrastructure/toast.service";
import { inject } from "@angular/core";

export function errorInterceptor(request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
    let toastService = inject(ToastService);
    let reqHeaders = request.headers;
    if (!reqHeaders.has('warning-allowed'))
        reqHeaders = reqHeaders.append('warning-allowed', '1');

    let newReq = request.clone({
        headers: reqHeaders
    });

    return next(newReq).pipe(
        tap(() => {},
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    let parsedError = parseError(err);
                    if (parsedError.appName === 'appName')
                        toastService.error(err.message, 'Error');
                    else
                        toastService.error(parsedError.detail, parsedError.title);
                }
            })
    );

    function parseError(error: HttpErrorResponse): ProblemDetails {
        let errorResp = error.error as ProblemDetails;
        if (errorResp?.traceId) {
            return errorResp;
        }

        return {
            appName: 'NotAvailable',
            traceId: '00-7139b73daab7e76adb15d10cc7e28e02-e94c3c3cd1748606-00',
            type: 'https://tools.ietf.org/html/rfc9110#section-15.6.1',
            detail: 'An unknown error occurred',
            exception: 'NotAvailable',
            instance: 'NotAvailable',
            title: 'An unknown error occurred',
            status: HttpStatusCode.InternalServerError
        }
    }
}