import { forkJoin, map } from 'rxjs';
import { environment } from "@env/environment";
import { inject } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { jwtDecode } from "jwt-decode";

export const initialDataResolver = () => {
    let authService = inject(AuthService);

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
        setupPermission(),
        setupUser()
    ]).subscribe();

    function setupUser() {
        return authService
            .user$
            .pipe(
                map(user => {
                    environment.userInfo = {
                        displayName: user.name,
                        mail: user.email,
                        jobTitle: user.name,
                        id: user.sub,
                        img: user.picture,
                    }

                    return true;
                }));
    }

    function setupPermission() {
        return authService
            .getAccessTokenSilently()
            .pipe(map(token => {
                let decodedToken = jwtDecode(token);
                environment.userInfo.permissions = decodedToken["permissions"] as string[];
                return true;
            }));
    }
};