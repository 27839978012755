import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthGuard } from "@auth0/auth0-angular";
import { initialDataResolver } from "./app.resolvers";
import { HealthCheckComponent } from "@shared/components/pages/health-check.component";

export const appRoutes: Route[] = [
    {
        path: 'ar_healthcheck',
        pathMatch: 'full',
        component: HealthCheckComponent
    },
    {
        path: 'open-area',
        pathMatch: 'prefix',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: '', title: 'Home', loadChildren: () => import('@modules/open-area/open-area.routes') }
        ]
    },
    {
        path: 'secure-area',
        pathMatch: 'prefix',
        canActivate: [ AuthGuard ],
        canActivateChild: [ AuthGuard ],
        component: LayoutComponent,
        children: [
            { path: '', title: 'Home', loadChildren: () => import('@modules/secure-area/secure-area.routes') }
        ],
        resolve: {
            initialData: initialDataResolver
        },
    },
    {
        path: '**',
        redirectTo: 'open-area'
    }
];